import React from 'react'

function Australia() {
  return (
    <div>
       <div className='doc-req'>
          <h2>STUDY IN AUSTRALIA- APPLICATION DOCUMENTS REQUIRED FOR NIGERIA STUDENTS</h2>

          <div className='doc-list'>
              <div className='reason-text'>
                  <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Attested Degree certificate, O`level or A`level Certificate</p>
               </div>

              <div className='reason-text'>
                  <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Evidence of English language Test e.g. IELTS, TOELF etc.</p>
               </div>

              <div className='reason-text'>
                  <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Standardized Test e.g. GMAT, GRE (where applicable)</p>
               </div>

               <div className='reason-text'>
                  <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Two references from an Employer (where applicable)</p>
               </div>

               <div className='reason-text'>
                  <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Any other Diploma or certificates.</p>
               </div>
               <div className='reason-text'>
                  <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Two academic references</p>
               </div>
               <div className='reason-text'>
                  <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Passport sized photographs</p>
               </div>
               <div className='reason-text'>
                  <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Statement of purpose</p>
               </div>
               <div className='reason-text'>
                  <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Resume/CV</p>
               </div>
               <div className='reason-text'>
                  <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Application fees</p>
               </div>

               <span>Note that the requirements may have some variations based on the institution requirement.</span>
          </div>
        </div>


      <div className='des-info'>
          <div className='desimg'>
          <img className='uni-img'  src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1709233105/342_bkd2cp.jpg" alt="" />    
          </div>

          <div className='des-text'>
             <h2>Why study in Australia?</h2>
             <p>There are several reasons why you should consider studying in Australia but the top reasons are:</p>

             <div className='reason'>

               <div className='reason-text'>
                 <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>It`s diverse</p>
               </div>
               
               <div className='reason-text'>
                 <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Employment prospects</p>
               </div>
               
               <div className='reason-text'>
               <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Schedule Flexibility</p>
               </div>
               
               <div className='reason-text'>
                  <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Learn English</p>
               </div>
               
               <div className='reason-text'>
                 <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Amazing innovation and Research</p>
               </div>
               
               <div className='reason-text'>
                  <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Popular with International students</p>
               </div>

               <div className='reason-text'>
                  <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>High quality of Education and Unique Curriculum</p>
               </div>
               
             </div>

          </div>
        </div>
    </div>
  )
}

export default Australia