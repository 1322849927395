import React, { useState } from "react";
import "./Contact.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import axios from "axios";

function Contact() {
  const toastOptions = {
    position: "top-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };
  
  const defaultData = {
    fullName: "",
    email: "",
    message: "",
  };

  const [data, setData] = useState(defaultData);
  const [loading, setLoading] = useState(false);
  console.log(data);
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const payload = {
        fullName: data.fullName,
        email: data.email,
        message: data.message,
        recipientEmail: "3ciconsultings@gmail.com",
      };
      const res = await axios.post(
        "https://smartwave-mail-api.onrender.com/send-contact-mail",
        payload
      );
      console.log(res);
      if (res.data.status === true) {
        setLoading(false);
        toast.success(res.data.message, toastOptions);
      }
      if (res.data.status === false) {
        setLoading(false);
        toast.error(res.data.error, toastOptions);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        setLoading(false);
        toast.error(error?.response?.data?.message, toastOptions);
      } else {
        setLoading(false);
        toast.error("An error occured while sending the mail", toastOptions);
      }
    }
  };

  return (
    <div>
      <div className="contact">
        <div className="contact-overlay">
          <h2>Contact Us</h2>
        </div>
      </div>

      <div className="contact-con">
        <div className="contact-form">
          <h2>
            Do you have any questions you need clarity on? Leave us a message
            and we will get right back to you.{" "}
          </h2>
          <form>
            <label>Name</label>
            <input type="text" name="fullName"
              onChange={(e) => handleChange(e)} placeholder="Enter your name" />
            <label className="pad">Email</label>
            <input
              type="email"
              name="email"
              onChange={(e) => handleChange(e)}
              placeholder="Enter your email address"
            />
            <label className="pad">Message</label>
            <textarea name="message" placeholder="Enter your message" 
              onChange={(e) => handleChange(e)}/>
            <ReCAPTCHA
              sitekey="6LdxBNgoAAAAAEmCy6CWJWQFL6izUl8ZDntvuhIH"
            />
            ,
            <input
              className="submit-btn"
              type="submit"
              onClick={handleSubmit}
              value={`${loading ? "Sending...." : "Send Message"}`}
            />
          </form>
        </div>

        <div className="contact-img"></div>
      </div>

      <div className="con-ad">
        <h2>Office Address</h2>
        <p>
          You can also contact us via these call lines and office address to set
          up a physical meet.
        </p>
        <div className="ad">
          <div className="ad-box">
            <img
              className="ad-icon"
              src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1698644058/icons8-home-50_b8xwr7.png"
              alt=""
            />
            <p>Head Office</p>
            <span>
              Bordilak Plaza, 2nd Floor Zone C,Block 9A, ASPMDA Trade Fair
              Complex,Badagry Express Way, Lagos
              <br></br>
              <br></br>Working Hours: 8.00 a.m. to 6.00 p.m. (Monday to Friday)
            </span>
          </div>

          <div className="ad-box">
            <img
              className="ad-icon"
              src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1698644058/icons8-home-50_b8xwr7.png"
              alt=""
            />
            <p>London Office</p>
            <span>
              5 Beechvale Close London,N129AW London,United Kingdom
              <br></br>
              <br></br>Working Hours: 8.00 a.m. to 6.00 p.m. (Monday to Friday)
            </span>
          </div>

          <div className="ad-box">
            <div className="mail">
              <img
                className="ad-icon"
                src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1698644058/icons8-mail-50_zdalij.png"
                alt=""
              />
              <p>Email Address</p>
              <Link
                className="mail-link"
                to={
                  "mailto:3ciconsultings@gmail.com?subject = Feedback&body = Message"
                }
              >
                3ciconsultings@gmail.com
              </Link>
            </div>

            <img
              className="ad-icon"
              src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1698644057/icons8-call-50_jy1akd.png"
              alt=""
            />
            <p>Call Line</p>
            <Link className="mail-link" to={"tel:+2348033896114"}>
              Tel: +2348033896114
            </Link>
          </div>

          <div className="ad-box">
            <img
              className="ad-icon"
              src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1698644058/icons8-social-50_imienn.png"
              alt=""
            />
            <p>Social Media</p>
            <div className="social-media">
              <Link
                className="mail-link"
                to={
                  "https://instagram.com/3ciconsulting?igshid=NzZlODBkYWE4Ng=="
                }
              >
                <img
                  className="soc"
                  src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1698661403/instagram_g4g1ri.png"
                  alt=""
                />
              </Link>
              <Link
                className="mail-link"
                to={"https://www.facebook.com/3Ciconsulting?mibextid=ZbWKwL"}
              >
                <img
                  className="soc"
                  src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1698661180/facebook_etp9mi.png"
                  alt=""
                />
              </Link>
              <Link className="mail-link" to={"https://x.com/3ci_ltd?s=09"}>
                <img
                  className="soc"
                  src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1698661167/twitter_n3fj9m.png"
                  alt=""
                />
              </Link>
              <Link className="mail-link" to={"https://wa.me/+2348033896114"}>
                <img
                  className="soc"
                  src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1698661297/whatsapp_aiv2eb.png"
                  alt=""
                />
              </Link>
              <Link
                className="mail-link"
                to={"https://www.linkedin.com/company/3ci-consulting-limited/"}
              >
                <img
                  className="soc"
                  src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1698661104/linkedin_m3r9cl.png"
                  alt=""
                />
              </Link>
            </div>
            <span>Connect with us on social media</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
