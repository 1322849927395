import React from 'react'
import './Uk.css'

function Uk() {
  return (
    <div>

       <div className='doc-req'>
          <h2>STUDY IN UNITED KINGDOM - APPLICATION DOCUMENTS REQUIRED FOR NIGERIA STUDENTS</h2>

          <div className='doc-list'>
              <div className='reason-text'>
                  <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Attested Degree certificate, O`level or A`level Certificate</p>
               </div>

              <div className='reason-text'>
                  <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Evidence of English language Test e.g. IELTS, TOELF etc.</p>
               </div>

              <div className='reason-text'>
                  <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Standardized Test e.g. GMAT, GRE (where applicable)</p>
               </div>

               <div className='reason-text'>
                  <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Two references from an Employer (where applicable)</p>
               </div>

               <div className='reason-text'>
                  <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Any other Diploma or certificates.</p>
               </div>
               <div className='reason-text'>
                  <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Two academic references</p>
               </div>
               <div className='reason-text'>
                  <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Passport sized photographs</p>
               </div>
               <div className='reason-text'>
                  <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Statement of purpose</p>
               </div>
               <div className='reason-text'>
                  <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Resume/CV</p>
               </div>
               <div className='reason-text'>
                  <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Application fees</p>
               </div>

               <span>Note that the requirements may have some variations based on the institution requirement.</span>
          </div>
        </div>

        <div className='des-info'>
          <div className='desimg'>
               <img className='uni-img' src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1709233105/famous-tower-bridge-evening-london-england_wrdcw1.jpg" alt="" />  
          
          </div>

          <div className='des-text'>
             <h2>Why study in the Uk?</h2>
             <p>There are several reasons why you should consider studying in the UK but the top reasons are:</p>

             <div className='reason'>
               <div className='reason-text'>
                  <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Impressive international reputation</p>
               </div>

               <div className='reason-text'>
                 <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Learn English: studying in UK will help you develop strong English language skills</p>
               </div>
               
               <div className='reason-text'>
                 <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>High teaching standard: The Quality Assurance Agency for Higher Education is the key body charged with maintaining these standards.</p>
               </div>
               
               <div className='reason-text'>
               <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Uk is cultural diversified</p>
               </div>
               
               <div className='reason-text'>
                  <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>Shorter completion time usually 3 years for undergraduate, 1 year for master and 3 years for PhD</p>
               </div>
               
               <div className='reason-text'>
                 <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>You will be allow to work up to 20 hours a week</p>
               </div>
               
               <div className='reason-text'>
                  <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
                  <p>You get financial assistance</p>
               </div>

              
               
             </div>

          </div>
        </div>

    </div>
  )
}

export default Uk