import React from 'react'
import './Scholarship.css'



function Scholarship() { 
 
  return (
    <div>
      <div className='contact'>
        <div className='contact-overlay'>
          <h2>Scholarship</h2>
        </div>
      </div>


      <div className='info'>
          <h2>SCHOLARSHIPS FOR NIGERIA STUDENTS | 3CI CONSULTING</h2>
            <p>A scholarship is an award of financial aid for a student to pursue their further education. Scholarships are awarded on various criteria as described by each institute and meant for development of education.We have developed this segment for those who outrank many others in scholastic ability and for those with both, the right academics and the right attitude to be the best. We provide opportunity to students through our strong official associations to avail scholarships at various universities and colleges around the World.

                  3Ci Consulting has strong partnerships with international universities and institutions hence are able to provide authentic and first-hand information to potential students & aspirants to obtain full or partial scholarships.<br></br> <br></br>Moreover, our team of career advisors will assist you & guide you with complete application process & documentation for scholarship application. Many of our students receive FULL and Partial scholarships each year, why wait and think when you can Apply Now!

                  Let us bridge this gap for you and assist you in making your dream destination a reality!!

                  In order to be eligible to apply for a scholarship you must have applied and received an offer from the university. If you have not applied yet, contact one of Offices closest to you and seek Advice.

                  In case of any doubts or queries, you may seek our assistance through email on scholarship@3Ciconsulting.com
            </p>
         </div>
      
      <div className='scholarship-con'>
        <div className='sch-left'>
          <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1698898912/award_ue3tx4.jpg" alt="" />
        </div>

        <div className='sch-right'>
          <p><strong>Important Information</strong><br></br>
            - Kindly provide correct details while filling up the 3Ci Consulting “Registration Form“. Selection of applications for scholarships are completely done by the respective institutes applied for & by fulfilling all their admission requirements. <br></br>
            - All applications received by 3Ci Consulting are given equal opportunity for assessment & only qualified applications meeting the requirement of the respective institute are sent for final process. <br></br>
            - Decision of approval and grant of scholarship is of the Institute. 3Ci Consulting does not guarantee or assure any student for the same. <br></br>
            - Processing time of assessment and application depends on the institute applied for, generally it takes from 8-12 weeks and 3Ci Consulting takes no responsibility in delayed process or no outcome of applications. Qualified and accepted students are advised to also directly liaison with the applied institute. <br></br>
            - Once pre-assessment is made on your applications and approved by respective institute or 3Ci Consulting , you will be asked to provide Attested Documents of your Academics, work experience documents and all other required documents as specified by the respective Institute. So, get yourself registered for Free and avail this opportunity at the earliest..!</p>
        </div>
      </div>
      
    </div>
  )
}

export default Scholarship