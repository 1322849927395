import React, { useState } from "react";
import './Navbar.css'
import {Link} from "react-router-dom"
import Cart from "../Cart/Cart"



const Navbar = () => {
  const [dropdown, setDropdown] = useState(false);

    const [navOpen, setNavOpen] = useState(false)
   
    return (
       <div>
         <div className="navbar">
          <div className='nav-con'>
          <Link to= {`./`}> 
            <div className='logo'>
              <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1710745412/ww_g1u748.jpg" alt="" /> 
            </div>
          </Link>

           <ul className="nav-items">
              <li>
                 <Link className="nav-list" to= {`./`}> Home</Link>
              </li>

              <li>
                 <Link className="nav-list" to= {`/about`}>About Us</Link>
              </li>

              <li onMouseEnter={() => setDropdown(true)} onMouseLeave={() => setDropdown(false)}>
                 <p className="nav-list"> Our Services</p>
                 {dropdown && <Cart />}
              </li>

              <li>
                 <Link className="nav-list" to= {`/study`}> Study Destinations</Link>
              </li>
              <li>

                 <Link className="nav-list" to= {`./application`}> Application Form</Link>
              </li>
           </ul>

  
            <div className='try-btn'>
               <Link  to= {`/contact`}> <button>Contact Us</button> </Link>  
               
             
                <div className='menu-toggle' onClick={() => setNavOpen(!navOpen)}>
                    <div className= {navOpen ? "hamBox hamBoxOpen" : "hamBox"}>
                        <span className={navOpen ? "lineTop spin" : "lineTop"}></span>
                        <span className={navOpen ? "lineBottom spin" : "lineBottom"}></span>
                    </div>
                </div>
            </div> 


            
            <div className='nav-overlay' style={{
                top: navOpen ? "0" : "-180%",
                transitionDelay : navOpen ? "0s" : "0s"
            }}>

                <ul className='nav-links'>
                    <li className='nav-item'>
                      <Link to= {`./`} onClick={() => setNavOpen(!navOpen)} style={{
                           top: navOpen ? "0" : "150px",
                           transitionDelay : navOpen ? "0" : "0s"   
                      }} >  Home</Link>
                       <div className='nav-wrapper'></div>
                    </li>  

                    <li className='nav-item'>
                      <Link to= {`./about`} onClick={() => setNavOpen(!navOpen)} style={{
                         top: navOpen ? "0" : "150px",
                         transitionDelay : navOpen ? "0s" : "0s" 
                      }}>About Us</Link>
                       <div className='nav-wrapper'></div>
                    </li>  


                    <li className="nav-item" onMouseEnter={() => setDropdown(true)} onMouseLeave={() => setDropdown(false)}>
                      <p className="nav-list">Our Services</p>
                      {dropdown && <Cart />}
                     </li>


                    
                    <li className='nav-item'>
                      <Link to= {`./study`} onClick={() => setNavOpen(!navOpen)} style={{
                         top: navOpen ? "0" : "150px",
                         transitionDelay : navOpen ? "0s" : "0s" 
                      }}>Study Destinations</Link>
                       <div className='nav-wrapper'></div>
                    </li>  

                    <li className='nav-item'>
                      <Link to= {`./application`} onClick={() => setNavOpen(!navOpen)} style={{
                         top: navOpen ? "0" : "150px",
                         transitionDelay : navOpen ? "0s" : "0s" 
                      }}>Application Form</Link>
                       <div className='nav-wrapper'></div>
                    </li>  
           
                </ul>
            </div>
          </div> 
         </div>

         
       </div>

      
      

    )
  }
export default Navbar


