import React from 'react'
import './Home.css'
import {Link} from "react-router-dom"
import Brief from '../../Components/Brief/Brief'
import HomeService from '../../Components/HomeService/HomeService'
import Studydes from '../../Components/Studydes/Studydes'
import Course from '../../Components/Course/Course'


function Home() {
  return (
    <div>
          <div className='hero'>
            <div className='hero-overlay'>
              <h2>Get Access To Quality Education Today!</h2>
              <p>Navigate your career with ease using our platform, featuring a range of educational services tailored to meet your specific needs.</p>

              <Link className='about-btn' to= {`./about`}>
                  <button>
                      Learn More                     
                  </button>
              </Link>
            </div>

            <div className='hero-img'>
              <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1710748411/3967_dg3l7b.jpg" alt="" />
            </div>
         </div>

         <Brief />   
         <HomeService /> 
         <Studydes />
         <Course />
    </div>
    
  )
}

export default Home