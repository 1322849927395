import React from 'react'
import './About.css'


function About() {
  return (
    <div>
        <div className='about'>
          <div className='about-overlay'>
              <h2>About Us</h2>
          </div>
        </div>

        <div className='about-con'>
          <div className='about-text'>
              <p>Who we are</p>
                  <span>3CI Consulting Limited is an International education consulting organization duly incorporated in Nigeria with excellent credentials in providing value-based services to both International Institutes and aspiring students in Nigeria.
                      <br></br><br></br>
                      We are one of the leading international students recruitment & Solutions Company in Nigeria assisting international institutes in students recruitment, market research and institutional tie ups.<br></br><br></br>
                      3Ci Consulting Limited came into being with over 10 years of combined experience in international education and recruitment with Professional offices all across Nigeria Mega cities. <br></br> <br></br>We offer open enrolment sessions and on-site seminars specifically tailored for Students. In addition to our Education Fair organized annually, we also conduct conferences in collaboration with our partners to provide a complete learning experience as we blend our presentations with interactive exercises, case studies, group discussions.
                  </span>
          </div>

          <div className='about-img'>
            <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697883875/young-student-working-assignment_np1bsg.jpg" alt="" />              
          </div>    
         </div>

         <div className='subtext'>
            <h2>Why Choose Us</h2>
            <p>We stand firm at 3Ci Consulting Limited in our commitment to offering top notch guidance and support to students wishing to study abroad. We operate with utmost sincerity and honesty and ensure that each of our clients leave us fully satisfied with our study abroad services.</p>        
        </div>

        <div className='vision'>
            <div className='vision-left'>
              <p>Our Vision</p>
              <span>We have a clear vision to be the world leader in educational consultancy in the near future and part of that vision is to establish deep and lasting relationships with education providers across Nigeria and overseas to reach our mutual objectives of career mapping and development for our students. This vision incorporates training, information sharing and knowledge distribution at all levels. This unique vision and understanding defines our point of difference and is the benchmark of our success and our client’s success.</span>             
            </div>

            <div className='vision-right'>
              <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697883870/young-adults-meeting-up-study_nikc8e.jpg" alt="" />
            </div>
        </div>

        <div className='mis-val'>
          <div className='mission'>
            <h2>Our Mission Statement</h2>
            
            <div className='misval-text'>
               <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
               <p>We always keeping in mind the best interest of our stakeholders, partners and clients.</p>
            </div>
            <div className='misval-text'>
               <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
               <p>We aim at transforming lives by offering the right education and career guidance for students.</p>
            </div>
            <div className='misval-text'>
               <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
               <p>Backed by our expert team of professional consultants, we hope to offer students an opportunity to identify the right universities thereby facilitating lifelong learning.</p>
            </div>
            <div className='misval-text'>
               <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
               <p>We ensure that each of our student goes through a hassle-free admission process to the University of their Choice.</p>
            </div>
            <div className='misval-text'>
               <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
               <p>We aim at informing students and organisations about the best educational institutions thus improving the level of learning.</p>
            </div>
            <div className='misval-text'>
               <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
               <p>Quality education promotes competency of employees and also results in overall growth and advancement of the society.</p>
            </div>
           
          </div>

          <div className='value'>
            <h2>Our Values</h2> 

            <div className='misval-text'>
               <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
               <p>Being customer focused</p>
            </div>
            <div className='misval-text'>
               <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
               <p>Working with integrity</p>
            </div>
            <div className='misval-text'>
               <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
               <p>Encouraging cultural diversity</p>
            </div>
            <div className='misval-text'>
               <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
               <p>Achieving excellence in whatever we do</p>
            </div>
            <div className='misval-text'>
               <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
               <p>Offering excellent quality consultancy service</p>
            </div>
            <div className='misval-text'>
               <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
               <p>Ensuring responsible counselling through effective team work</p>
            </div>

          </div>
        </div>
       
    </div>
  )
}

export default About