import React from 'react'

import './HomeService.css'

function HomeService() {
  return (
    <div className='serv'>  
           <div className='homeservice-text'>
            <h3>Our Services</h3>
                <h2>Top services we provide</h2>
            </div> 

          <div  className='homeservice-con'>          
            <div className='homeservice'>
                <div className='homeservice-box'>
                    <div className='ellipse'></div>
                    <div className='homeservice-text'>
                        <p>Scholarships</p>  
                        <span>3Ci Consulting has strong partnerships with international universities and institutions hence are able to provide authentic and first-hand information to potential students & aspirants to obtain full or partial scholarships.</span>
                    </div>       
                </div>

                <div className='homeservice-box'>
                <div className='ellipse'></div>
                    <div className='homeservice-text'>
                    <p>Visa counselling</p>  
                    <span>Our study abroad visa consultant will provide you with all necessary interview questions expected where interview is required and will guide you through the key aspects of the interview with the High Commission or Embassy.</span>
                    </div>     
                </div>

                <div className='homeservice-box'>
                    <div className='ellipse'></div>
                    <div className='homeservice-text'>
                    <p>University admission services</p>  
                    <span>3Ci Consulting has knowledgeable and informative Counsellors to provide you the opportunity for global education, assist you on career and course choices as well place you on reputable university of your choice.</span>
                    </div>    
                </div>        
            </div>

        </div>     
    </div>
  )
}

export default HomeService