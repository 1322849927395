import {
  createBrowserRouter,
  RouterProvider,
  Outlet
} from "react-router-dom";
import ScrollTop from './Components/Scrolltop'
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import Study from './Pages/Study/Study';
import Summer from './Pages/Summer/Summer';
import Writing from './Pages/Writing/Writing';
import Contact from './Pages/Contact/Contact';
import Application from './Pages/Application/Application';
import Uk from './Pages/Uk/Uk';
import Us from './Pages/Us/Us';
import Australia from './Pages/Australia/Australia';
import Canada from './Pages/Canada/Canada';
import Europe from './Pages/Europe/Europe';
import Policy from "./Pages/Policy/Policy";
import Disclaimer from "./Pages/Disclaimer/Disclaimer";
import Career from "./Pages/Career/Career";
import Scholarship from "./Pages/Scholarship/Scholarship";
import './App.css';
import University from "./Pages/University/University";
import Value from "./Pages/Value/Value";
import Loans from "./Pages/Loans/Loans";
import Institute from "./Pages/Institute/Institute";
import Cart from "./Components/Cart/Cart";
import { ToastContainer } from "react-toastify";


const Layout = () =>{
  return(
    <div className="main">
      <ScrollTop />
      <Navbar />
      <Outlet/>
      <Footer />
    </div>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout/>,
    children:[
      {
        path:"/",
        element:<Home/>,
      },

      {
        path:"/about",
        element:<About/>,
      },

      {
        path:"/university",
        element:<University/>,
      },

      {
        path:"/value",
        element:<Value/>,
      },

      {
        path:"/loans",
        element:<Loans/>,
      },

      
      {
        path:"/institute",
        element:<Institute/>,
      },

      {
        path:"/study",
        element:<Study/>,
      },
      {
        path:"/contact",
        element:<Contact/>,
      },

      {
        path:"/cart",
        element:<Cart/>,
      },

      {
        path:"/summer",
        element:<Summer/>,
      },

      {
        path:"/writing",
        element:<Writing/>,
      },

      {
        path:"/application",
        element:<Application/>,
      },

      {
        path:"study/Uk/",
        element:<Uk/>,
      },

      {
        path:"study/Us/",
        element:<Us/>,
      },

      {
        path:"study/Australia/",
        element:<Australia/>,
      },

      {
        path:"study/Canada/",
        element:<Canada/>,
      },

      {
        path:"study/Europe/",
        element:<Europe/>,
      },

      {
        path:"/policy",
        element:<Policy/>,
      },

      {
        path:"/disclaimer",
        element:<Disclaimer/>,
      },

      {
        path:"/career",
        element:<Career/>,
      },

      {
        path:"/scholarship",
        element:<Scholarship/>,
      },

    ]
  },

])

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />   
      <ToastContainer/>    
    </div>
  );
}

export default App;

