import React from 'react'
import './Disclaimer.css'

function Disclaimer() {
  return (
    <div>
        <div className='contact'>
         <div className='contact-overlay'>
           <h2>Disclaimer</h2>
         </div>
        </div>

        <div className='policy'>
           <h2>DISCLAIMER</h2>
            <p>3Ci Consulting Ltd has attempted to make the information on this server as accurate as possible, the information on this website server is for personal and/or educational use only and is provided in good faith without any express or implied warranty. There is no guarantee given as to the accuracy or currency of any individual item on the website. Persons accessing the website who require confirmation of any information should refer to 3Ci Consulting`s sourcing the information for this website. 3Ci Consulting does not accept responsibility for any loss or damage occasioned by use of the information contained on the server nor from any access to the server. While 3Ci Consulting will make every effort to ensure the availability and integrity of its resources, it cannot guarantee that these will always be available, and/or free of any defects, including viruses. Users should take this into account when accessing the resources. All access and use is at the risk of the user.</p>
        </div>
    </div>
  )
}

export default Disclaimer