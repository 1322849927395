import React from 'react'
import {Link} from "react-router-dom"
import './Study.css'

function Study() {
  return (
    <div className='studydestination'>
        <div className='study'>
          <div className='study-overlay'>
              <h2>Study Destination</h2>
          </div>
        </div>

       <div className='studydes'>
          <div className='des'>
                <div className='brief-text'>
                    <p>Study Destination</p>
                    <h2> Wherever you are, you can still study abroad.</h2>
                </div>

                <div className='brief-img'>
                <span>
                    3Ci Consulting can help you achieve your dreams of studying from home. Obtain an international recognized degree from some of the world's renowned institutions. We provide all you need to know about distance learning from any part of the world.
                    </span>
                </div>    
            </div>  
      </div>

      <div className='studydes-con'>
            <div className='uni-box'>
               <img className='uni-img' src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1709233105/famous-tower-bridge-evening-london-england_wrdcw1.jpg" alt="" />  
               <Link to= {`./Uk`} className='uni-name'>
                    <span>Study in the UK</span>           
                </Link>
            </div>

            <div className='uni-box'>
               <img className='uni-img' src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1709233105/445_nv8txl.jpg" alt="" />  
               <Link to= {`./Us`} className='uni-name'>
                    <span>Study in the USA</span>                              
                </Link>
            </div>

            <div className='uni-box'>
               <img className='uni-img' src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1709233105/3901_h4jg0v.jpg" alt="" />  
               <Link to= {`./Canada`} className='uni-name'>
                    <span>Study in Canada</span>          
                </Link>
            </div>

            <div className='uni-box'>
               <img className='uni-img' src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1709233106/1148_dw7vem.jpg" alt="" />  
                <Link to= {`./Europe`} className='uni-name'>
                    <span>Study in Europe</span>              
                </Link>
            </div>

            <div className='uni-box'>
               <img className='uni-img' src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1709233105/342_bkd2cp.jpg" alt="" />  
                <Link to= {`./Australia`} className='uni-name'>
                    <span>Study in Australia</span>              
                </Link>
            </div>
        </div>
        
        <div></div>
    </div>
  )
}

export default Study