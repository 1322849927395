import React from 'react'
import './Footer.css'
import {Link} from "react-router-dom"
import Cta from '../Cta/Cta'

function Footer() {
  const currentYear = new Date().getFullYear();
  
  return (
    <div className='Footer'>
      <Cta />
      <div className='footer'>
      <div className='footer-box'>
        <div className='footer-links'>
          <p>Company</p>
           <Link className='sub-link' to= {`./about`}>About Us </Link>
           <Link className='sub-link' to= {`./contact`}>Contact Us </Link>
        </div>

        <div className='footer-links'>
          <p>Academic Resources</p>
          <Link className='sub-link' to= {`/university`}>Profiling </Link>
          <Link className='sub-link' to= {`/university`}>Standard Test </Link>
          <Link className='sub-link' to= {`/university`}>Document Editing </Link>
          <Link className='sub-link' to= {`/university`}>Interview Preparation</Link>
          <Link className='sub-link' to= {`/university`}>Visa Counselling </Link>
          <Link className='sub-link' to= {"/university"}>Application Review and Submission</Link>
        </div>

        <div className='footer-links'>
          <p>Guides</p>
          <Link className='sub-link' to= {"https://3cipof.vercel.app/"}>Proof Of Funds </Link>
          <Link className='sub-link' to= {"/scholarship"}>Scholarship</Link>
          <Link className='sub-link' to= {`./career`}>Career</Link>
          <Link className='sub-link' to= {"/contact"}>Online Enquiry</Link>
          <Link className='sub-link' to= {"/writing"}>Academic Writing Help</Link>
          <Link className='sub-link' to= {"/summer"}>Summer Education Tour</Link>
       
        </div>

        <div className='footer-links'>
          <p>Social Media</p>
           <Link className='sub-link' to= {"https://www.facebook.com/3Ciconsulting?mibextid=ZbWKwL"}>Facebook </Link>
           <Link className='sub-link' to= {"https://wa.me/+2348033896114"}>WhatsApp </Link>
           <Link className='sub-link' to= {"https://instagram.com/3ciconsulting?igshid=NzZlODBkYWE4Ng=="}>Instagram </Link>
           <Link className='sub-link' to= {"https://x.com/3ci_ltd?s=09"}>Twitter </Link>
           <Link className='sub-link' to= {"https://www.linkedin.com/company/3ci-consulting-limited/"}>Linkedin </Link>
        </div>
      </div>

      <div className='copy'>
        <p>{currentYear} © 3ci Consulting Limited. All rights reserved.</p>

        <div className='disclaimer'>
           <Link className='sub-link' to= {`./disclaimer`}>Disclaimer</Link>
           <Link className='sub-link' to= {`./policy`}>Policy</Link>
        </div>
      </div>

      </div>
    </div>
  )
}

export default Footer