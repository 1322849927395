import React, { useState } from 'react'
import "./Cart.css"
import {Link} from "react-router-dom"


function Cart() {
   const [dropdown, setDropdown] = useState(false);

  return (
    <div className= {dropdown ? "cart clicked" : "cart" } onClick={() => setDropdown(!dropdown)}  >
       <div className='service-box'>
         <div>
           <Link className='service-head' onClick={() => setDropdown(false)}   to= {`/university`}> University Admission Services </Link>
         </div>
         
         <div className='service-type'>
          <Link onClick={() => setDropdown(false)}  to= {`/university`}> PROFILING </Link>
          <Link  onClick={() => setDropdown(false)} to= {`/university`}> STANDARDIZED TESTS</Link>
          <Link onClick={() => setDropdown(false)}  to= {`/university`}> COUNTRY/UNIVERSITY/COURSE SELECTION</Link>
          <Link onClick={() => setDropdown(false)}  to= {`/university`}>  DOCUMENT EDITING</Link>
          <Link onClick={() => setDropdown(false)}  to= {`/university`}> APPLICATION REVIEW AND SUBMISSION</Link>
          <Link onClick={() => setDropdown(false)}  to= {`/university`}> INTERVIEW PREPARATION</Link>
          <Link  onClick={() => setDropdown(false)} to= {`/university`}> VISA COUNSELLING</Link>
         </div>
       </div>
       
       <div className='service-box'>
         <div>
           <Link className='service-head' onClick={() => setDropdown(false)}   to= {`/value`}> Value Added Services </Link>
         </div>

         <div className='service-type'>
          <Link onClick={() => setDropdown(false)} to= {`/value`}> PRE DEPARTURE BRIEFING SERVICES</Link>
          <Link  onClick={() => setDropdown(false)} to= {`/value`}> ACCOMODATION SERVICES </Link>
          <Link onClick={() => setDropdown(false)} to= {`/value`}> TRAVEL AND MEDICAL INSURANCE </Link>
          <Link onClick={() => setDropdown(false)} to= {`/value`}> FOREIGN EXCHANGE SERVICES</Link>
        
         </div>
       </div>

       <div className='service-box'>
         <div>
           <Link className='service-head' onClick={() => setDropdown(false)}  to= {`/institute`}> For Institutions</Link>
         </div>

         <div className='service-type'>
          <Link onClick={() => setDropdown(false)}  to= {`/institute`}> OUR MARKETING STRATEGIES </Link>
          <Link onClick={() => setDropdown(false)} to= {`/institute`}> OFFSHORE SUPPORT SERVICES</Link>
          <Link onClick={() => setDropdown(false)} to= {`/institute`}> STUDENT EXHIBITION AND SEMINARS </Link>
          <Link onClick={() => setDropdown(false)} to= {`/institute`}> WHY PARTNER WITH 3CI CONSULTING </Link>
         </div>
       </div>

       <div className='service-box'>
         <div>
           <Link className='service-head' onClick={() => setDropdown(false)}  to= {`/loans`}> Education Loans </Link>
         </div>

         <div className='service-type'>
          <Link onClick={() => setDropdown(false)}  to= {"https://3cipof.vercel.app/"}> PROOF OF FUNDS </Link>
          <Link onClick={() => setDropdown(false)}  to= {`/scholarship`}> SCHOLARSHIPS </Link>
         </div>
       </div>

       
    </div>
  )
}

export default Cart