import React from 'react'
import './Brief.css'


function Brief() {
  return (
    <div className='brief'>
        <div className='brief-text'>
            <p>Our Capabilities</p>
            <h2>Studying abroad has never been easier.</h2>
        </div>

        <div className='brief-img'>
           <span>
               3CI Consulting Limited is an International education consulting organization duly incorporated in Nigeria with excellent credentials in providing value-based services to both International Institutes and aspiring students in Nigeria.
                <br></br>
                We are one of the leading international students recruitment & Solutions Company in Nigeria assisting international institutes in students recruitment, market research and institutional tie ups.
            </span>
        </div>    
    </div>
  )
}

export default Brief