import React from 'react'
import './Policy.css'

function Policy() {
  return (
    <div>
        <div className='contact'>
         <div className='contact-overlay'>
           <h2>Policy</h2>
         </div>
        </div>

        <div className='policy'>
            <h2>PRIVACY POLICY</h2>
            <p>The 3Ci Consulting Limited recognizes the need of privacy to visitors of our website ("www.3ciconsulting.com"). This Privacy Policy explains what type of information is collected from you when you visit our website and discloses the privacy practices for all 3Ci Consulting web pages.</p>

            <div className='policy-con'>
                <div className='policy-box'>
                    <p>Contact Information We Collect</p>
                    <span>3Ci requires you to provide contact information (such as name, address, e-mail address, country of residence) when you request information about our services. This contact information is used to provide you with the specific information you requested in the most appropriate manner. Occasionally, you may receive subsequent notifications of important information regarding the programs and/or services for which you made your initial inquiry.</span>
                </div>

                <div className='policy-box'>
                    <p>Email Policy</p>
                    <span>If you send us email messages, you should be aware that information disclosed in email is not secure or encrypted and thus may be available to others. We suggest that you exercise caution when deciding to disclose any personal or confidential information in email. We may use your email address to respond directly to your questions or comments. <br></br><br></br>We will not share, sell, rent or swap your email address for commercial purposes.</span>
                </div>

                <div className='policy-box'>
                    <p>Information Protection</p>
                    <span>We take reasonably steps to protect your data, so make sure to prevent unauthorized access, maintain data accuracy and ensure the appropriate use of information we gather about you, we have taken commercially reasonable steps to protect the information.</span>
                </div>

                <div className='policy-box'>
                    <p>Your Consent</p>
                    <span>By using this site ("3ciconsulting.com "), you consent to the collection and use of this information by the 3Ci Consulting as described in this Privacy Policy. If we decide to change our Privacy Policy, we will post those changes on this page so that you are always aware of what information we collect, how we use it, and under what circumstances we disclose it.</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Policy