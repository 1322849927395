import React from 'react'
import './Career.css';

function Career() {
  return (
    <div>
       <div className='contact'>
        <div className='contact-overlay'>
          <h2>Career</h2>
        </div>
      </div>

      <div className='career-con'>
         <div className='career-left'>
          <h2>CAREERS AT 3CI CONSULTING LTD</h2>
          <p>3Ci Consulting apart from being a phenomenal new organization also is a very reliable and trustworthy employer of labour. People who work at 3Ci Consulting vouch for this fact. Our employees play a critical role in contributing to our mission in an environment that’s clients focused, synergetic, achievement-oriented, and competitive. From promising college graduates to seasoned statesmen, the employees of 3Ci Consulting endorse our mission through fresh ideas, resolute innovation, and hard work.To register your interest to work with us, kindly send your C.V to career@3ciconsulting.com</p>

         </div>

         <div className='career-right'>
           <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1698898899/career_oqz3d8.jpg" alt="" />
         </div> 
      </div>
    </div>
  )
}

export default Career