import React from "react";
import "./Application.css";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import axios from "axios";

function Application() {
  const toastOptions = {
    position: "top-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  const defaultData = {
    academic_preference_study_destination: "",
    academic_preference_programme_type: "",
    academic_preference_prefferred_institute_first: "",
    academic_preference_prefferred_institute_second: "",
    academic_preference_study_course_first: "",
    academic_preference_study_course_second: "",
    academic_preference_start_month: "",

    student_details_fullname: "",
    student_details_dob: "",
    student_details_gender: "",
    student_details_nationality: "",
    student_details_address: "",
    student_details_city: "",
    student_details_phone: "",
    student_details_email: "",

    education_history_high_school_start: "",
    education_history_high_school_end: "",
    education_history_high_school_name: "",
    education_history_high_school_certificate: "",
    education_history_tertiary_institution: "",
    education_history_course_studied: "",
    education_history_start_date: "",
    education_history_end_date: "",
    education_history_grade: "",

    guardian_details_fullname: "",
    guardian_details_gender: "",
    guardian_details_nationality: "",
    guardian_details_address: "",
    guardian_details_city: "",
    guardian_details_phoneNumber: "",
    guardian_details_email: "",
    guardian_details_guardian_age: "",

    emergency_contact_fullname: "",
    emergency_contact_gender: "",
    emergency_contact_nationality: "",
    emergency_contact_address: "",
    emergency_contact_city: "",
    emergency_contact_phone: "",
    emergency_contact_email: "",

    crimainal_record_dismissed: "",
    crimainal_record_convicted: "",
    crimainal_record_denied_visa: "",

    financial_info_annual_fund: "",
    financial_info_responsibility: "",

    standard_testing_date: "",
    standard_testing_test_type: "",
    standard_testing_score: "",
  };

  const [data, setData] = useState(defaultData);
  const [loading, setLoading] = useState(false);
  console.log(data);
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const payload = { ...data, recipientEmail: "3ciconsultings@gmail.com" };
      const res = await axios.post(
        "https://smartwave-mail-api.onrender.com/send-application-mail",
        payload
      );
      console.log(res);
      if (res.data.status === true) {
        setLoading(false);
        toast.success(res.data.message, toastOptions);
      }
      if (res.data.status === false) {
        setLoading(false);
        toast.error(res.data.error, toastOptions);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        setLoading(false);
        toast.error(error?.response?.data?.message, toastOptions);
      } else {
        setLoading(false);
        toast.error("An error occured while sending the mail", toastOptions);
      }
    }
  };

  return (
    <div>
      <div className="contact">
        <div className="contact-overlay">
          <h2>Application Form</h2>
        </div>
      </div>

      <div className="form-disclaimer">
        <p>
          Please completely fill out the form as all the information is required
          for us to help you process your application.
        </p>
      </div>
      

      <div className="application-con">
        <div className="contact-form">
          <form>
            <label className="first-header">Academic Preference</label>

            <label>Study Destination</label>
            <input
              className="text"
              name="academic_preference_study_destination"
              placeholder="Eg: Uk, USA, Canada, Australia and Europe"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Programme Type</label>
            <select
              type=""
              name="academic_preference_programme_type"
              onChange={(e) => handleChange(e)}
            >
              <option className="option" value="Undergraduate">
                Undergraduate
              </option>
              <option className="option" value="Postgraduate Taught">
                Postgraduate Taught
              </option>
              <option className="option" value="Postgraduate Research">
                Postgraduate Research
              </option>
              <option className="option" value="PhD">
                PhD
              </option>
              <option className="option" value="Pathway">
                Pathway
              </option>
              <option className="option" value="Top-up">
                Top-up
              </option>
            </select>

            <label className="pad">Preferred Institution(First Choice)</label>
            <input
              type="text"
              name="academic_preference_prefferred_institute_first"
              placeholder="Eg: Cambridge College"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Preferred Institution(Second Choice)</label>
            <input
              type="text"
              name="academic_preference_prefferred_institute_second"
              placeholder="Eg: Havard"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Study Course(First Choice)</label>
            <input
              type="text"
              name="academic_preference_study_course_first"
              placeholder="Eg: Computer Engineering"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Study Course(Second Choice)</label>
            <input
              type="text"
              name="academic_preference_study_course_second"
              placeholder="Eg: Law"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Start Month</label>
            <input
              className="date"
              type="month"
              name="academic_preference_start_month"
              onChange={(e) => handleChange(e)}
            />

            <label className="second-header">Student Details</label>

            <label className="pad">Full Name (Surname First)</label>
            <input
              type="Text"
              name="student_details_fullname"
              placeholder="John Mark Doe"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Date Of Birth</label>
            <input
              className="date"
              type="date"
              name="student_details_dob"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Gender</label>
            <input
              type="Text"
              name="student_details_gender"
              placeholder="Male or Female"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Nationality</label>
            <input
              type="Text"
              name="student_details_nationality"
              placeholder="Eg: Nigerian"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Address</label>
            <input
              type="Text"
              name="student_details_address"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">City</label>
            <input
              type="Text"
              name="student_details_city"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Phone Number</label>
            <input
              type="Text"
              name="student_details_phone"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Email</label>
            <input
              type="email"
              name="student_details_email"
              placeholder="Enter your email address"
              onChange={(e) => handleChange(e)}
            />

            <label className="second-header">Educational History</label>

            <label className="pad">High School Start Month</label>
            <input
              type="month"
              name="education_history_high_school_start"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">High School End Month</label>
            <input
              type="month"
              name="education_history_high_school_end"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Name of High School</label>
            <input
              type="text"
              name="education_history_high_school_name"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Certificate Obtained</label>
            <input
              type="text"
              name="education_history_high_school_certificate"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Tertiary Institution</label>
            <input
              type="text"
              name="education_history_tertiary_institution"
              placeholder="Name of institution"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Course Studied</label>
            <input
              type="text"
              name="education_history_course_studied"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Start Date</label>
            <input
              type="date"
              name="education_history_start_date"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">End Date</label>
            <input
              type="date"
              name="education_history_end_date"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Grade</label>
            <input
              type="text"
              name="education_history_grade"
              onChange={(e) => handleChange(e)}
            />

            <label className="second-header">Parent or Guardian Details</label>

            <label className="pad">Full Name (Surname First)</label>
            <input
              type="Text"
              name="guardian_details_fullname"
              placeholder="John Mark Doe"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Gender</label>
            <input
              type="Text"
              name="guardian_details_gender"
              placeholder="Male or Female"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Nationality</label>
            <input
              type="Text"
              name="guardian_details_nationality"
              placeholder="Eg: Nigerian"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Address</label>
            <input
              type="Text"
              name="guardian_details_address"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">City</label>
            <input
              type="Text"
              name="guardian_details_city"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Phone Number</label>
            <input
              type="Text"
              name="guardian_details_phoneNumber"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Email</label>
            <input
              type="email"
              name="guardian_details_email"
              placeholder="Enter your email address"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Is your guardian above the age of 18?</label>
            <input
              type="Text"
              name="guardian_details_guardian_age"
              placeholder="Yes or No"
              onChange={(e) => handleChange(e)}
            />

            <label className="second-header">Emergency Contact</label>

            <label className="pad">Full Name (Surname First)</label>
            <input
              type="Text"
              name="emergency_contact_fullname"
              placeholder="John Mark Doe"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Gender</label>
            <input
              type="Text"
              name="emergency_contact_gender"
              placeholder="Male or Female"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Nationality</label>
            <input
              type="Text"
              name="emergency_contact_nationality"
              placeholder="Eg: Nigerian"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Address</label>
            <input
              type="Text"
              name="emergency_contact_address"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">City</label>
            <input
              type="Text"
              name="emergency_contact_city"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Phone Number</label>
            <input
              type="Text"
              name="emergency_contact_phone"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Email</label>
            <input
              type="email"
              name="emergency_contact_email"
              placeholder="Enter your email address"
              onChange={(e) => handleChange(e)}
            />

            <label className="second-header">
              Criminal or Disciplinary Record
            </label>

            <label className="pad">
              Have you ever been dismissed from any institution?
            </label>
            <input
              type="Text"
              name="crimainal_record_dismissed"
              placeholder="Yes or No"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">
              Have you ever been convicted of any crime?
            </label>
            <input
              type="Text"
              name="crimainal_record_convicted"
              placeholder="Yes or No"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">History of Visa denial?</label>
            <input
              type="Text"
              name="crimainal_record_denied_visa"
              placeholder="Yes or No"
              onChange={(e) => handleChange(e)}
            />

            <label className="second-header">
              Financial Information and agreement
            </label>

            <label className="pad">Annual Fund In US Dollar</label>
            <input
              type="Text"
              name="financial_info_annual_fund"
              placeholder="Eg: 20,000"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">
              Who is responsible for your financial support?
            </label>
            <input
              type="Text"
              name="financial_info_responsibility"
              placeholder="Family, Friend or any other"
              onChange={(e) => handleChange(e)}
            />

            <label className="second-header">Standardized Testing</label>

            <label className="pad">Date of Test</label>
            <input
              className="date"
              type="date"
              name="standard_testing_date"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Test Type</label>
            <input
              type="Text"
              name="standard_testing_test_type"
              placeholder="Eg: GRE, GMAT, TOEFL or IELTS"
              onChange={(e) => handleChange(e)}
            />

            <label className="pad">Score</label>
            <input
              type="Text"
              name="standard_testing_score"
              placeholder="Eg: 98"
              onChange={(e) => handleChange(e)}
            />

            <input
              onClick={handleSubmit}
              value={`${loading ? "Sending...." : "Send Message"}`}
              className="submit-btn"
              type="submit"
            />
          </form>
        </div>
      </div>

      <div className="scripts">
        <p>
          Also submit the following documents to our support email address,
          after which you will receive an email from us to acknowledge receiving
          your documents.
        </p>
        <span>Detailed CV </span>
        <span>O level certificates </span>
        <span>Degree Certificates </span>
        <span>All Transcript</span>
        <span>Passport Data page</span>
        <span>Work or Academic Reference </span>
        <span>Personal Statement</span>
        <button>
          <Link
            to={
              "mailto:info@3ciconsulting.com?subject = Feedback&body = Message"
            }
          >
            Submit Here
          </Link>
        </button>
      </div>

      <div className="enq">
        <p>For further enquiries send us a message on whatsApp or via email</p>
        <div className="enq-btn">
          <button>
            <Link to={"https://wa.me/+2348033896114"}>Chat on whatsApp</Link>
          </button>

          <button>
            <Link
              to={
                "mailto:3ciconsultings@gmail.com?subject = Feedback&body = Message"
              }
            >
              Send mail
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Application;
