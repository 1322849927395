import React from 'react'
import './Summer.css'

function Summer() {
  return (
    <div className='acad'>
      <div className='summer'>
        <div className='summer-img'>
          <img className='des-img' src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1709233155/WhatsApp_Image_2024-02-29_at_00.19.41_72b22765_duvdht.jpg" alt="" /> 
        </div>

        <div className='summer-con'>
         <h2>SUMMER EDUCATION TOURS</h2>
         <p>At 3Ci Consulting, we make possible to assist teachers and students in Nigeria to travel and experience different cultures, have an inspired new global perspective on the world as well navigate and explore new languages, historic sites in order to promote a global perspective that are needed for success in today`s globalised and interconnected world.</p>
        </div>    
      </div>      

      <div className='summer-text'>
        <h2>SUMMER TOUR PACKAGE</h2>

        <div className='reason-text'>
          <p><strong>Education Study Tour</strong> <br></br> We organised summer study tours for Nigeria students from age of 10 to 18 years to join their mates from around the world to partake in summer school in London, Paris, Rome, South Africa, New York. We work in partnership with some overseas travelling agencies and Schools. We take students out of their comfort zone and expose them internationally into an experience that make them more open-minded.</p>
        </div>

        <div className='reason-text'>
          <p><strong>Global Students Leader Summit</strong> <br></br> We assist institutions to partake in this unique summit that enhances leadership training. This training together with educational tour focused more on global issues.</p>
        </div>

        <div className='reason-text'>
          <p><strong>Service Learning Tour</strong><br></br>We Conduct research and help you find reliable sources you can use for your writing</p>
        </div>

        <div className='reason-text'>
          <p> <strong>Language Immersion Tour</strong><br></br> We assist students from Nigeria to learn foreign language in another country. The tour will not only make students learn a new language but make them fall in love with them.</p>
        </div>

        <div className='reason-text'>
          <p> <strong>Custom-Designed Tour</strong><br></br> Are you still searching for perfect tour that suits your goals? Search no more because we are here to assist you from design to travelling. We assist organisations and schools to design tours in any time of the year. We can modify an existing itinerary or assist to design from scratch. FOR MORE INFORMATION, CONTACT US @ contact@3ciconsulting.com</p>
        </div>
       </div>
  </div>
  )
}

export default Summer