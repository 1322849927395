import React from 'react'
import './Writing.css'

function Writing() {
  return (
    <div className='acad'>
      <div className='acad-con'>
         <h2>ACADEMIC WRITING SERVICES</h2>
         <p>We provide students the opportunity to seek academic writing help outside the school environment to the group of professional writers with many years of writing experience. Our writers are experienced in the art of writing and are willing to offer mentorship. All our writers are current PhD scholars in top universities in the United Kingdom and United State. Student often require writing help when the writing skills are lacking. We offer guidance and tutoring for them to confront their assignments, term papers, dissertations, and thesis. Our company provides high level of proficiency and quality to whoever we served. . Writing help is given for many different assignments such as essay writing help, term paper writing help, research paper writing help, thesis writing help, dissertation writing help and much more. Writing assistance is provided to students to guide them in writing of different academic tasks that they have to do compulsorily for their academic program. Students should seek academic writing help from a company which can provide them with qualitative service and product.

         <br></br> <br></br> We also provide online help for convenience of our students who are in need of our writing services. And we are ready to seek wherever you are. Our services are unparalleled and we promise high grades and commendable and praiseworthy from your academic teachers.

         <br></br> <br></br>  If you have any problems in formatting/referencing, you can get professional APA writing help, MLA writing help, Turabian writing help or Harvard writing help from our writers, including those that hold Ph.D. and Master's degrees however you can also request for advanced High school writing help, College writing help and University writing help.
          </p>
      </div>

      <div className='writing-services'>
        <div className='ws-img'>
          <img className='des-img' src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1709233155/WhatsApp_Image_2024-02-29_at_00.21.51_39ee25bd_okv3ow.jpg" alt="" /> 
        </div>

        <div className='ws-text'>
          <h2>OUR WRITING SERVICES INCLUDE:</h2>

          <div className='reason-text'>
            <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
            <p>We assist you to choose the best topic that suites your academic goals, mentor you while you write and draft.</p>
          </div>

          <div className='reason-text'>
            <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
            <p>We edit your work with aid of writing software and expertise of our PhD writers to make necessary amendments to your paper to make it polished</p>
          </div>

          <div className='reason-text'>
            <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
            <p>We Conduct research and help you find reliable sources you can use for your writing</p>
          </div>

          <div className='reason-text'>
            <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
            <p>We proofread and edit Books too, term papers, research papers or dissertations.</p>
          </div>
         </div>
      </div>      
    </div>
  )
}

export default Writing