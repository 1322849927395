import React from 'react'

function Value() {
  return (
    <div>
      <div className='study'>
          <div className='study-overlay'>
              <h2>Our Services</h2>
          </div>
        </div>

        <div className='service-brand'>
           <h2>VALUE ADDED SERVICES</h2>
           <p>Our love for our students has no bound that is why we are don’t stop at the stage of admission process and visa application. We ensure that our students are guided till departure, we assist them too to sort out their accommodation, flight ticket, travel and medical insurance and foreign exchange services. At 3Ci Consulting, we enhance the journey experience of our students and ensure total study abroad services hassle free.
            </p>
        </div>

        <div className='sub-brand'>
           <div>
             <p>PRE DEPARTURE BRIEFING SERVICES</p>
             <span>At 3Ci Consulting, our aim is to see the best come out from students study abroad programme so we make the processes easy for them by providing pre departure briefing before departure that will assist them understand the culture and practices of the intending study destinations. We motivate our students to allay fear that comes with travelling to new destination by sharing our thought and first hand experiences. Our pre departure briefing cover other areas such as practises and systems of the new destination, fundamental laws and the procedures of study overseas. We also brief on the expectation on arrival, arrange meet and greet and link the student with other students going to same university and location.</span>
           </div>

           <div>
             <p>ACCOMODATION SERVICES</p>
             <span>At 3Ci Consulting, we assist all our students in Nigeria and beyond find suitable accommodation even before departure. We encourage our students to get accommodation before departure. This advice is borne out of our experiences in international students’ recruitment services. Accommodation is one of the most daunting task for a new student, this is one of the reasons 3Ci Consulting is the leading educational consulting firm because we are here to serve you better. We utilize our wide range of contacts in different country to make your study experience seamless. Our wide range of housing providers and students union across various universities is an advantage our students have over others. No matter what your taste is we are here to make it possible.</span>
           </div>

           <div>
             <p>TRAVEL AND MEDICAL INSURANCE</p>
             <span>Many countries for study abroad have it as a regulation that all international students especially ones across their continents to have medical insurance while studying in their country. Some countries also require travel insurance for the consulate to grant visa to Nigerian students wishing to study abroad. We at 3Ci Consulting Nigeria are happy to render you such a service. Our relationship with travel and health providers in Nigeria will make you have huge discounts on your travel and medical insurance hassle free. We also provide international insurance services through our foreign partners.</span>
           </div>

           <div>           
             <p>FOREIGN EXCHANGE SERVICES</p>
             <span>It is imperative and we advise students to sort out foreign exchange transactions before travelling. At 3Ci Consulting Nigeria, we offer our students in Nigeria foreign exchange solutions through our sister company Ebube Bureau de Change. We advise our students on other foreign exchange requirements on their study destinations. We assist students to get FOREX from the Banks in Nigeria. These services are to ensure their have hassle free study experience especially at the initial period of departure and arrival in their study destination.</span>
           </div>

        </div>

    </div>
  )
}

export default Value