import React from 'react'

function Institute() {
  return (
    <div>
       <div className='study'>
          <div className='study-overlay'>
              <h2>Our Services</h2>
          </div>
        </div>

        <div className='service-brand'>
           <h2>FOR INSTITUTIONS | PARTNERSHIP</h2>
           <p>Our love for our students has no bound that is why we are don’t stop at the stage of admission process and visa application. We ensure that our students are guided till departure, we assist them too to sort out their accommodation, flight ticket, travel and medical insurance and foreign exchange services. At 3Ci Consulting, we enhance the journey experience of our students and ensure total study abroad services hassle free.
            </p>
        </div>

        <div className='sub-brand'>
           <div>
             <p>OUR MARKETING STRATEGIES</p>
             <span> <strong>Education Fairs- </strong>
                   We organise our education fairs in cities represented by us. These fairs are only open to institutes represented by 3Ci Consulting Limited. Institutes meet Pre-screened and pre assessed students in each location so as to target more serious and focused students. These fairs provide institutes excellent opportunity to meet students face to face and give offer letters to qualified students. <br></br><br></br>

                   <strong>Direct Focused marketing- </strong>
                   We design more Focused marketing activities targeting the specific audience / students. It is far more important to have a personal and more focused approach to each individual market and offer the right product to the right individual. Identifying customers according to the Product and focusing on maintaining the Market support. <br></br><br></br>

                   
                   <strong>Public Relations- </strong>
                   To be featured in credible forums to announce the new features and achievements. Take leadership position in the Career space by contributing articles / information in the Leading publications. Organize and participate in events to enhance the brand building of the company. <br></br><br></br>

                   
                   <strong>Advertising- </strong>
                    We take positions in the education / business publications conveying rational reasons to explore the Option of International Education. Facilitating the support systems available through well-established offices. We also use printed materials and radios in our campaign. <br></br><br></br>

                   
                   <strong>Events- </strong>
                   Participating in and organizing exhibitions, Seminars / Career fairs in Association with Foreign Universities to give more personal interaction to the students and Opening their Minds to various learning opportunities they have Abroad. Hence interact with the customers more personally and demonstrate and build credibility of the company & associated Institutes. <br></br><br></br>

                   
                   <strong>Presentations- </strong>
                   Promotions in schools & colleges – Build mind share with the future workforce. Presenting the new opportunities offered to them and building a whole New World for them. Working Ties with the schools & Colleges to have Education Events conducted regularly in their Premises. Inviting our Institutes to give presentations in the well know schools, Colleges & Universities to create more Direct awareness on the Opportunities available to students in Nigeria. <br></br><br></br>

                   
                   <strong>Workshops- </strong>
                   To conduct regular workshops for intellect sharing’ concept. The workshops are conducted in association with Nigeria Universities & Universities we represent to have face 2 face open room discussions. The workshops will also be focused on particular courses done in order to allow more interactivity between the faculty from abroad and students enrolled for their courses. </span>
           </div>

           <div>
             <p>OFFSHORE SUPPORT SERVICES</p>
             <span> 3Ci Consulting has qualified student counsellors who work individually with students to advise them on the right education choice to achieve their career goals. This means that information about your institution is delivered face-to-face to prospective students. <br></br><br></br>
                   We also support institutional clients in the following services : <br></br>
                  -Client Relations Managers to act as liaison points for clients in monitoring student trends, market insight and advising on potential opportunities <br></br>
                  -Support for in-country visits, including promotions and administration<br></br>
                  -In-country events<br></br>
                  -Promotion of scholarship opportunities<br></br>
                  -Strategic marketing plans and implementation<br></br>
                  -Offshore translation, printing, mailing and distribution of materials and brochure
            </span>
           </div>

           <div>
             <p>STUDENT EXHIBITION AND SEMINARS</p>
             <span>3Ci Consulting regularly holds exhibitions, seminars and interview programs throughout its network, where students have the opportunity to meet with institution representatives. Students and their parents can spend time discussing entry requirements, course details, and recognition of awards, career opportunities, institution facilities and related student support services.</span>
           </div>

           <div>           
             <p>WHY PARTNER WITH 3CI CONSULTING</p>
             <span>3Ci Consulting is a highly reputable and ethical company and a leader in international student placement. We understand the different markets and cultures which have assisted us understand different requirements needed for proper and quality student recruitment services. 3Ci Consulting, we work in partnership with client institutions across all education sectors, from secondary schools to universities, to recruit international students who want to study abroad. By understanding an individual client’s goals, we help to position them to their best advantage in an increasingly competitive international environment.</span>
           </div>

        </div>
    </div>
  )
}

export default Institute