import React from 'react'
import './Cta.css'
import {Link} from "react-router-dom"

function Cta() {
  return (
    <div className='cta'>
       <div className='cta-con'>
        <h2>Discover the world of international education with us</h2>
        <Link  to= {`./application`}> <button>Apply Now</button> </Link> 
       </div>
    </div>
  )
}

export default Cta