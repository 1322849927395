import React from 'react'
import "./University.css"


function University() {
 
  return (
    <div>
       <div className='study'>
          <div className='study-overlay'>
              <h2>Our Services</h2>
          </div>
        </div>

        <div className='service-brand'>
           <h2>UNIVERSITY ADMISSION SERVICES</h2>
           <p>We understand the daunting tasks students and parents face in choosing the choice of foreign institutions for their wards. It is daunting because there are different institutions, countries, courses and different financial implications to choose from. That is why we are in business, our business is to assist students make choice and process it for them while parents concentrate on their businesses or jobs.

            3Ci Consulting has knowledgeable and informative Counsellors to provide you the opportunity for global education, assist you on career and course choices as well place you on reputable university of your choice. We offer comprehensive educational packages to assist Nigeria students study abroad.

            We achieve this through SEVEN STEPS.
            </p>
        </div>

        <div className='sub-brand'>
           <div>
             <p>Step 1: PROFILING</p>
             <span>One of our student’s Counsellor will be assign to you for an interview or profiling section. This exercise is to give professional advice that base suit individual cases after examine the career aspirations, academic qualifications, work experience and financial status of the student. We will accurately advise base on the available information on the best country, university and course.</span>
           </div>

           <div>
             <p>Step 2: STANDARDIZED TESTS</p>
             <span>If you have taken any standardised test, one of our professional Counsellor will review it to be sure that it is in consistence with the university benchmark. The tests are not limited to GRE, GMAT, SAT and TOELF but also any other aptitude test that evaluate students aptitude to undertake higher education. These standardized tests are meant to evaluate students and their aptitude and compare them in a consistent manner. We also advise students on the test to take if no test have been taken or where poor result base on course chosen and university.</span>
           </div>

           <div>
             <p>Step 3: COUNTRY/UNIVERSITY/COURSE SELECTION</p>
             <span>The next stage of the entire process after standardized test review is the country/university and course selection. It is important to make the right choice when it comes to studying abroad because studying abroad does not come easy and cheap. Mistake made will be costly when the studies commence. At 3Ci Consulting, we ensure that right thing is done that’s assisting you to make the right choice of country and course. Our professional Counsellor will assist you in this regard.</span>
           </div>

           <div>           
             <p>Step 4: DOCUMENT EDITING</p>
             <span>Your visa approval is depending on the submitting the right documents. This is the first step towards ensuring that your visa is granted. We ensure we maintained our record of near 100% visa approval rating on our study abroad student recruitment in Nigeria. Any false document submitted will lead to visa refusal and possible ban and can lead to cancellation of admission while visa issuance may be delayed in case of any little inconsistency of document submitted. 3Ci Consulting professional and editorial team will be responsible for review, redrafting and editing to improve the content, grammar and structure and consequently presented a professional documents to the institution and for visa application.</span>
           </div>

           <div>
             <p>Step 5: APPLICATION REVIEW AND SUBMISSION</p>
             <span>This is your final chance to review the applications before they are submitted to the chosen universities. This is a critical part of the process and you must ensure that you set aside ample time to focus on this stage. You will be expected to sit with 3Ci Consulting team of experienced application professionals to ensure that all details are accurate and to confirm that you are satisfied with the final application that has to be submitted.</span>
           </div>

           <div>
             <p>Step 6: INTERVIEW PREPARATION</p>
             <span>3Ci Consulting consultants are aware of the type of questions different universities ask prospective students at the interviews where interview is required. To help prepare for any interviews that may be required by the chosen universities, we provide you with a number of interview preparatory sessions. The interview preparation sessions are conducted by professionals who have vast experience in interview techniques and are well versed with the nature and scope of questions that universities usually ask their prospective students.</span>
           </div>

           <div>
             <p>Step 7: VISA COUNSELLING</p>
             <span>It is not possible to study abroad with relevant visa. 3Ci Consulting professional visa Counsellor will provide you necessary assistance towards visa application submission. In order to avoid rejection or delay, our consultant will assist you to be sure right documents are submitted. Our study abroad consultant will provide comprehensive advice and ensure all relevant documents that are needed are submitted along with the visa application. Our study abroad visa consultant will provide you with all necessary interview questions expected where interview is required and will guide you through the key aspects of the interview with the High Commission or Embassy. 3Ci Consulting also provide with its Nigeria students other range of Value Added Services together with the University Admission Services. It is the time you have to take that bold step and enrol. Contact us today to experience hassle free process towards study abroad services.</span>
           </div>
        </div>
    </div>
  )
}

export default University